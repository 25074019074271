import { BlockStack, Box, Button, ButtonGroup, Card, Checkbox, DataTable, DataTableProps, Divider, InlineError, InlineStack, Loading, MenuGroupDescriptor, Page, Text, Tooltip } from "@shopify/polaris";
import { DeleteIcon } from "@shopify/polaris-icons";
import { Footer } from "@shopify/polaris/components/Modal/components";
import { CubesDinero, DataQueryGraph, FieldClass, Prisma, SPPI, String, StringPathProxy, TABLE_NAMES, TableViewColumn, WHERE_balanceWhereLine, field, ok, okNull, proxy, root } from "common";
import { DataService } from "data-service";
import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { ButtonAwait, CardTitle, StatusBadge, Tone, emitGlobalRefresh, useAngular, useAsyncEffect, useLoadingMarkup, useObservable, useObserver, useRefresh, useSubscriptionClosed } from "react-utils";
import { NEVER } from "rxjs";
import { QuestionForm } from "../components/QuestionForm";
import { QuestionTableComp } from "../components/QuestionTableComp";
import { FormsQuestionService } from "../utils/forms-question.service";
import { DataPage, GroupDialog } from "../utils/question-dialog";
import { UIService } from "../utils/ui.service";
import { useBranchSelector } from "../utils/useBranchSelector";
import { DataListColumn, FGCV, QuestionGroup, QuestionValues } from "../utils";
import { TableView, TableViewClass, TableViewOptions } from "../tables/table-views";
import { useTableListInner } from "../tables/TableListInner";
import { capitalize } from "@shopify/polaris/utilities/capitalize";
import { TableViewColumnCustom, CustomTable, useTableData } from "../tables/useCustomTable";
import { ledgerTableColumns, ledgerTableViews } from "./page-branch-ledger";
import { plural } from "pluralize";
import { format } from "date-fns";


export function PageBranchSalesTax() {
  const { get, injector } = useAngular();
  const fq = get(FormsQuestionService);
  const ui = get(UIService);
  const data = get(DataService);
  const refresh = useRefresh();

  const actionGroups: MenuGroupDescriptor[] = [];

  const { curBranch, curBranchTitle, branchSelectorActionGroup } = useBranchSelector();

  if (data.status.branchType === "CENTRAL" && branchSelectorActionGroup)
    actionGroups.push(branchSelectorActionGroup);

  const [by, setBy] = useState<"month" | "quarter">("quarter");

  // const [balance, setBalance] = useState(0);

  const [showMethodChange, setShowMethodChange] = useState(false);

  const [curColOptions, setColOptions] = useState<readonly TableViewColumnCustom<any, any>[]>([
    { title: capitalize(by), key: "period", filterType: "text", calculate: e => e.period },
  ]);

  const paymentsMarkup = useBranchPayments(curBranch);

  const { result: balance } = useAsyncEffect(async () => {
    if (curBranch) {
      const { branches, salesTax } = await data.server.queryBranchBalance({ AND: [{ id: curBranch }] });
      const salesTaxBalance = salesTax.find(e => e[0] === curBranch)?.[1] || 0;
      const branchBalance = branches.find(e => e[0] === curBranch)?.[1] || 0;
      return { salesTaxBalance, branchBalance };
    } else {
      return undefined;
    }
  }, undefined, undefined, [curBranch]);

  // const taxesData = useTableData(() => curColOptions, [curColOptions], async () => {
  //   const { list, rows } = await getSalesTaxTable(data, curBranch, by);
  //   if (curBranch) {
  //     const { branches, salesTax } = await data.server.queryBranchBalance({ AND: [{ id: curBranch }] });
  //     setBalance(salesTax.find(e => e[0] === curBranch)?.[1] || 0);
  //   }
  //   setColOptions(list);
  //   return rows;
  // }, [curBranch, by], "period");

  const { result: taxesData } = useAsyncEffect(async () => {
    const list = await getSalesTaxTable(data, curBranch, by, showMethodChange);
    list.rows = list.rows.map(row => {
      for (let i = 1; i < row.length; i++) {
        if (typeof row[i] === "number")
          row[i] = DataListColumn.textCubesDinero(row[i]);
      }
      return row;
    });
    return list;
  }, undefined, undefined, [curBranch, by, showMethodChange]);

  const branchStatus = useBranchStatusButtons(curBranch, balance);

  return <Page
    title={"Sales Tax for " + curBranchTitle}
    actionGroups={actionGroups}
    fullWidth
  >
    <BlockStack gap="400">
      <Card padding="400">
        <BlockStack gap="400">
          <CardTitle title="Sales Tax Payouts" padding="0" >
            <InlineStack gap="200" align="start">
              {branchStatus}
            </InlineStack>
          </CardTitle>
          {(curBranch && balance) ? <>
            <Text as="p" variant="bodyMd">Payouts Balance: {DataListColumn.textCubesDinero(balance.branchBalance)}</Text>
            <Text as="p" variant="bodyMd">Sales Tax Balance: {DataListColumn.textCubesDinero(balance.salesTaxBalance)}</Text>
          </> : null}
          <p>
            You can request your sales tax payouts at any time, typically whenever you need to pay your sales tax.
            If you owe money to central, you won't be able to request more than the total balance.
          </p>
          {paymentsMarkup}
        </BlockStack>
      </Card>
      <Card padding="400">
        <BlockStack gap="400">
          <CardTitle title="Sales Tax Summary" padding="0" />
          <p style={{ maxWidth: "40rem" }}>These are the numbers needed for filing your sales tax.</p>
          <p style={{ maxWidth: "40rem" }}>
            At some point you will be able to record the exact amount
            of your sales tax payouts in order to properly keep track of your balance.
          </p>


          {data.status.isArlen && <Checkbox checked={showMethodChange} onChange={setShowMethodChange} label={<>
            Toward the end of 2024, the method for calculating sales tax changed.
            Click here to show the amount it changed by in the table below.
          </>}></Checkbox>}

          <ButtonGroup>
            <Button onClick={() => setBy("quarter")} variant={by === "quarter" ? "primary" : "secondary"} >View by Quarter</Button>
            <Button onClick={() => setBy("month")} variant={by === "month" ? "primary" : "secondary"}  >View by Month</Button>
          </ButtonGroup>

          {taxesData ? <DataTable {...taxesData} /> : null}
          <p style={{ maxWidth: "40rem" }}>
            These numbers should only change if a customer payment gets voided or has not cleared yet.
            The payment date determines which {by} the sales tax is in.
            If multiple payments apply to a single invoice line, the date of the last payment is used.
          </p>
        </BlockStack>
      </Card>
    </BlockStack>
  </Page>;
}

function useBranchStatusButtons(branchID: string, balance: { salesTaxBalance: number; branchBalance: number; } | undefined): React.JSX.Element {
  const fq = useAngular().get(FormsQuestionService);
  const data = useAngular().get(DataService);

  const { result: stripeStatus } = useAsyncEffect(async () => {
    if (!branchID) return;
    return await data.server.serverStripeAccountGetStatus({ PaymentLedger: "Branch", hostID: branchID });
  });

  const hasinfo = !!stripeStatus?.payouts_enabled;
  const requirements = stripeStatus?.requirements;
  const hasFutureReqs = !!requirements?.eventually_due?.length;
  const hasCurrentReqs = !!requirements?.currently_due?.length;
  const currentdue = requirements?.current_deadline ? "by " + new Date(requirements.current_deadline).toLocaleString() : "soon";

  if (!branchID) return <Text as="p" variant="bodyMd">No Branch selected.</Text>;

  const availableBalance = !balance ? 0 : balance.salesTaxBalance + (balance.branchBalance < 0 ? balance.branchBalance : 0);
  const availableBalanceStr = DataListColumn.textCubesDinero(availableBalance);
  return <>
    <StatusArray children={[
      [
        !!hasinfo,
        "success",
        "Payouts enabled",
        "attention",
        "Payouts disabled"
      ],
      [
        hasFutureReqs,
        hasCurrentReqs ? "critical" : "warning",
        hasCurrentReqs ? "Stripe needs more info " + currentdue : "Stripe needs more info eventually",
        "success",
        "Sripe info up to date"
      ]
    ]} />
    <ButtonAwait disabled={availableBalance <= 0 || !hasinfo} onClick={async () => {
      await fq.messageConfirmation(
        "Confirm sales tax payout",
        `Are you sure you want to request a sales tax payout in the amount of ${availableBalanceStr}?`,
        async () => {
          await data.server.serverRequestSalesTaxPayout({ branchID, amount: Math.max(0, availableBalance) });
          emitGlobalRefresh();
        }
      );
    }}>Request Sales Tax Payout of {availableBalanceStr}</ButtonAwait>
  </>
}

function StatusArray({ children }: { children: [boolean, Tone, string, Tone, string][]; }) {
  return <>{children.map(([value, trueTone, trueLabel, falseTone, falseLabel]) =>
    <StatusBadge {...{ value, trueTone, trueLabel, falseTone, falseLabel }} />
  )}</>;
}


abstract class Grouper<T, R> extends Map<string, R> {

  public abstract ingest(a: T): void;
  constructor() {
    super();
  }

  map<T>(fn: (value: R, key: string, map: Map<string, R>) => T, thisArg?: any): T[] {
    return [...this.entries()].map(([key, value]) => fn(value, key, this), thisArg);
  }

}

class OuterGrouper extends Grouper<TaxTableData, InnerGrouper> {
  constructor(public innerby: "month" | "quarter", public innerField: "paidOn" | "paidOnOld") {
    super();
  }
  ingest(e: TaxTableData) {
    const DisplayName = e.branch?.DisplayName;
    ok(DisplayName);

    if (!this.has(DisplayName))
      this.set(DisplayName, new InnerGrouper(this.innerby, this.innerField));

    this.get(DisplayName)!.ingest(e);
  }
}

class InnerGrouper extends Grouper<TaxTableData, {
  Total: number,
  Taxable: number,
  Exempt: number,
  SalesTax: Record<string, number>,
}> {
  Jurisdictions: Set<string> = new Set();
  constructor(public by: "month" | "quarter", public group: "paidOn" | "paidOnOld") {
    super();
  }
  ingest(e: TaxTableData) {
    let key;
    const date = e[this.group];
    if (!date) return;
    if (this.group === "paidOnOld" && date.slice(0, 4) > "2024") return;
    // ok(date); // the where clause should have filtered out nulls
    if (this.by === "quarter") {
      const month = +date.split("-")[1];
      const quarter = (month - 1) / 3 | 0;
      const year = date.split("-")[0];
      key = `${year} Q${quarter + 1}`
    } else {
      const month = date.split("-")[1];
      const year = date.split("-")[0];
      key = `${year}-${month}`;
    }

    if (!this.has(key)) this.set(key, {
      Total: 0,
      Taxable: 0,
      Exempt: 0,
      SalesTax: {}
    });

    const totals = this.get(key)!;

    ok(e.line.customerLedgerLine);
    const TaxTotal = e.line.salesTaxLedgerLine.reduce((a, f) => a + f.Amount, 0);
    const Customer = e.line.customerLedgerLine.Amount - TaxTotal;

    totals.Total += Customer;

    if (TaxTotal === 0)
      totals.Exempt += Customer;
    else
      totals.Taxable += Customer;

    e.line.salesTaxLedgerLine.forEach(f => {
      const key = `${f.TaxJurisdiction} Sales Tax (${f.TaxPercent}%)`;
      if (!totals.SalesTax[key]) totals.SalesTax[key] = 0;
      totals.SalesTax[key] += f.Amount;
      this.Jurisdictions.add(key);
    })

  }

}

const taxTableDataQuery = (curBranch: string | undefined) => proxy.invoiceLine.findMany({
  select: {
    branch: { select: { DisplayName: true } },
    paidOn: true,
    paidOnOld: true,
    line: {
      select: {
        Date: true,
        customerLedgerLine: { select: { Amount: true } },
        salesTaxLedgerLine: { select: { Amount: true, TaxJurisdiction: true, TaxPercent: true } },
      }
    }
  },
  where: {
    branchID: curBranch,
    line: {
      AND: [
        {
          VoidSince: null,
          IS_TESTING: false,
          OR: [
            // invoice lines that haven't arrived yet do not get paid out, even if they're paid
            { invoiceLine: { OR: [{ paidOn: { not: null } }, { paidOnOld: { not: null } }] } },
            // these would be payout lines, not customer payments
            { paymentLine: { PaymentStatus: "Cleared" } },
            { paymentLine: { PaymentStatus: "Approved" } },
            // no idea what these are. Probably adjustments. They should be included regardless.
            { paymentLine: null, invoiceLine: null, }
          ]
        }
      ],
    }
  }
});
type TaxTableData = Awaited<ReturnType<typeof taxTableDataQuery>>[number];

export async function getSalesTaxTable(data: DataService,
  curBranch: string | undefined, by: "month" | "quarter",
  showMethodChange: boolean
): Promise<DataTableProps> {
  const res = await data.singleDataQuery(taxTableDataQuery(curBranch || undefined));

  const groupsNew = new OuterGrouper(by, "paidOn");
  const groupsOld = new OuterGrouper(by, "paidOnOld");

  res.forEach(e => { groupsNew.ingest(e); groupsOld.ingest(e); });

  console.log(groupsNew, groupsOld);

  const list: DataTableProps = {
    columnContentTypes: [
      "text",
      "numeric",
      "numeric",
      "numeric"
    ],
    headings: [
      capitalize(by),
      "Gross Reciepts (before tax)",
      "Reciepts exempt from sales tax",
      "Taxable Reciepts (before tax)"
    ],
    rows: [],
  }

  // const customField = (key: string, index: number) => new FieldClass(key, index, new CubesDinero(false), false, false);
  // const list: TableViewColumnCustom<any, any>[] = [
  //   { title: capitalize(by), key: "period", filterType: "text", calculate: e => e.period },
  //   { title: "Gross Reciepts (before tax)", key: "Total", filterType: "currency", calculate: e => e.Total },
  //   { title: "Reciepts exempt from sales tax", key: "Exempt", filterType: "currency", calculate: e => e.Exempt },
  //   { title: "Taxable Reciepts (before tax)", key: "Taxable", filterType: "currency", calculate: e => e.Taxable },
  // ];

  const mapper = (period: string, oldVal: number, newVal: number) => {
    const showChange = showMethodChange && period.slice(0, 4) < "2025";
    const diff = newVal - oldVal;
    const tone = diff > 0 ? "success" : diff < 0 ? "critical" : undefined;
    const updown = diff > 0 ? "/\\" : diff < 0 ? "\\/" : "||";
    console.log(oldVal, newVal, diff, tone);
    return <>
      <Text as="span">{DataListColumn.textCubesDinero(newVal)}</Text>
      {showChange && tone && <>
        <Text as="span"> {updown} </Text>
        <Text as="span" tone={tone}>{DataListColumn.textCubesDinero(diff)}</Text>
      </>}
    </>
  }
  if (curBranch) {
    const valuesNew = [...groupsNew.values()];
    const valuesOld = [...groupsOld.values()];
    if (valuesNew.length > 1) debugger;
    if (valuesOld.length > 1) debugger;
    if (valuesNew.length === 0 && valuesOld.length === 0) return list;
    const totalsOld = valuesOld[0];
    const totalsNew = valuesNew[0];
    if (!totalsOld && !totalsNew) return list;
    const juris = new Set([...totalsOld.Jurisdictions, ...totalsNew.Jurisdictions]);
    juris.forEach(jurisdiction => {
      list.columnContentTypes.push("numeric");
      list.headings.push(jurisdiction);
    });

    const periods = new Set([...totalsOld.keys(), ...totalsNew.keys()]);
    console.log(totalsOld, totalsNew, juris, periods);
    const rows = [...periods.keys()].map(period => {
      const totalsOld2 = totalsOld.get(period);
      const totalsNew2 = totalsNew.get(period);
      ok(Object.keys(totalsOld2?.SalesTax || {}).every(e => juris.has(e)));
      ok(Object.keys(totalsNew2?.SalesTax || {}).every(e => juris.has(e)));
      // [...temp4.keys()].map(e => [...temp3.keys()].map(k => temp1.get(e).SalesTax[k]))
      // [...[...temp3.keys()]].map(k => temp1.get(e).SalesTax[k])
      return [
        period,
        mapper(period, totalsOld2?.Total || 0, totalsNew2?.Total || 0),
        mapper(period, totalsOld2?.Exempt || 0, totalsNew2?.Exempt || 0),
        mapper(period, totalsOld2?.Taxable || 0, totalsNew2?.Taxable || 0),
        ...[...juris.keys()].map((key) => {
          const old = totalsOld2?.SalesTax[key] || 0;
          const new2 = totalsNew2?.SalesTax[key] || 0;
          return mapper(period, old, new2);
        })
      ] as const;
    }).sort((a, b) => {
      return -a[0].localeCompare(b[0]);
    });
    console.log(rows, juris);
    list.rows = rows as any;
    return list;
    // const rows = totalsOld.map((totalsOld2, period) => {
    //   const totalsNew2 = totalsNew.get(period);
    //   const { Exempt, Taxable, Total } = totalsOld2;
    //   ok(Object.keys(totalsOld2.SalesTax).every(e => juris.has(e)));
    //   ok(Object.keys(totalsNew2?.SalesTax || {}).every(e => juris.has(e)));
    //   return [period, Total, Exempt, Taxable, ...Object.values(SalesTax)] as const;
    // }).sort((a, b) => {
    //   return -a[0].localeCompare(b[0]);
    // });
    // list.rows = rows as any;
    // return list;
  } else {
    list.columnContentTypes.unshift("text");
    list.headings.unshift("Branch");

    list.columnContentTypes.push("numeric");
    list.headings.push("Total Tax");

    // list.unshift({ title: "Branch", key: "branch", filterType: "text", calculate: e => e.branch });
    // list.push({ title: "Total Tax", key: "Tax", filterType: "currency", calculate: e => e.Tax });
    // const juris = new Set([...totalsOld.Jurisdictions, ...totalsNew.Jurisdictions]);
    // const periods = new Set([...totalsOld.keys(), ...totalsNew.keys()]);
    const branches = new Set([...groupsOld.keys(), ...groupsNew.keys()]);
    const rows: any[] = [];
    branches.forEach(branch => {
      const totalsOld = groupsOld.get(branch);
      const totalsNew = groupsNew.get(branch);
      if (!totalsOld && !totalsNew) return;
      const periods = new Set([...totalsOld?.keys() ?? [], ...totalsNew?.keys() ?? []]);
      periods.forEach(period => {
        const totalsOld2 = totalsOld?.get(period);
        const totalsNew2 = totalsNew?.get(period);
        if (!totalsOld2 && !totalsNew2) return;
        const taxOld = Object.values(totalsOld2?.SalesTax ?? {}).reduce((a, f) => a + f, 0);
        const taxNew = Object.values(totalsNew2?.SalesTax ?? {}).reduce((a, f) => a + f, 0);

        rows.push([
          branch,
          period,
          mapper(period, totalsOld2?.Total || 0, totalsNew2?.Total || 0),
          mapper(period, totalsOld2?.Exempt || 0, totalsNew2?.Exempt || 0),
          mapper(period, totalsOld2?.Taxable || 0, totalsNew2?.Taxable || 0),
          mapper(period, taxOld, taxNew),
        ])
      });
    });
    rows.sort((a, b) => {
      const [brancha, perioda] = a;
      const [branchb, periodb] = b;
      return -brancha.localeCompare(branchb) || -perioda.localeCompare(periodb);
    });

    list.rows = rows as any;
    return list;
  }




}

function useBranchPayments(curBranch: string | undefined) {

  const { get, injector } = useAngular();
  const ui = get(UIService);
  const data = get(DataService);
  const fq = get(FormsQuestionService);

  const views = useMemo(() => [TableViewClass.makeClientView("SalesTaxLedger", {
    key: "payments",
    title: "Payments",
    list: x => [
      x.line.Date.__,
      x.line.paymentLine.PaymentStatus.__,
      { key: x.Amount.__, title: "Branch" },
      { key: x.line.paymentLine.txnID.__, hidden: true },
    ],
    sort: x => [
      `-${x.line.Date.__}` as SPPI,
    ],
    AND: [{
      branchID: curBranch || undefined,
      line: {
        VoidSince: null,
        IS_TESTING: false,
        paymentLine: { is: {} },
      }
    }] satisfies Prisma.BranchLedgerWhereInput[],
  })], [curBranch]);

  const hiddenColumns = StringPathProxy<"SalesTaxLedger">()(x => curBranch ? [x.branch.DisplayName.__] : []);

  const onSelectRow = (id: string | undefined) => {
    if (id) fq.onClickLedgerLine({ salesTaxLedgerLine: { some: { id } } }, false);
  }

  return useTableListInner({ table: "SalesTaxLedger", views, hiddenColumns, onSelectRow, hideFilters: true }).useMarkup();

  // return <TableListInner
  //   table={"SalesTaxLedger"}
  //   views={views}
  //   hiddenColumns={hiddenColumns}
  //   onSelectRow={onSelectRow}
  //   hideFilters
  // />;
}