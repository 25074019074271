import { PrismaQuery, SRResult } from "common";
import { DataService } from "data-service";
import React from "react";
import { useAngular, useLoadingMarkup } from "react-utils";
import { DataListColumn, FormsQuestionService } from "../utils";
import { CustomTable, useTableData } from "../tables/useCustomTable";
import { Banner } from "@shopify/polaris";

const tabLabels = ["All", "Ready", "Failed", "Attempts"] as const;
const tabEnum = Object.fromEntries(new Map(tabLabels.map((e, i) => [e, i]))) as Record<typeof tabLabels[number], number>;
const tableloader = async (data: DataService) => {
  let oldValue = new Map((await data.server.queryGetAutopayForCustomerLedger({ AND: [] })).map(e => [e.lineID, e] as const));
  const customers = (await data.server.queryCustomerAutopayStatus_new({ where: {}, includeOld: false }));

  const newValue = new Map(customers
    .flatMap(customer => customer.LedgerLines.filter(e => e.details.isRental).map(e => ({ id: e.lineID, nv: e, customer })))
    .map(e => [e.id, e] as const));
  const lineIDs = new Set([...Array.from(newValue.keys()), ...Array.from(oldValue.keys())]);
  return [...lineIDs.keys()].map(e => {
    const { nv, customer } = newValue.get(e) ?? {};
    const ov = oldValue.get(e);
    return ({
      id: e, nv, ov,
      customer,
      Date: nv?.Date ?? ov?.Date,
      Name: customer?.billing?.Name ?? ov?.customerBilling?.Name,
      unit: nv?.unit ?? ov?.rental.unit,
      Amount: nv?.Amount ?? ov?.Amount,
      Email: customer?.Email ?? ov?.customerEmail,
      customerID: customer?.id ?? ov?.customerID,
    })
  }).filter(e => !e.ov?.isFuture && !e.nv?.details.isFuture);

}
// type tlrt = Awaited<ReturnType<typeof tableloader>>;
// const simplebool = (key: string, calculate: (e: tlrt[number]) => any) => ({ key, title: key, filterType: "boolean" as const, calculate })

/** curTab needs to be keyed */
export function AutopayTable({ curBranch, curTab, setTab, showFilters }: { curBranch: string | undefined, curTab: number, setTab?: React.Dispatch<React.SetStateAction<number>>, showFilters?: boolean }) {
  const { get } = useAngular();
  const data = get(DataService);
  const fq = get(FormsQuestionService);



  const loadingMarkup = useLoadingMarkup("customer ledger lines");
  const emptyMarkup = () => <Banner title="No Unpaid Fees" tone="success" />;
  // const tableData = curTab === tabEnum.Attempts ? useTableData(() => [
  //   { key: "Date", title: "Autopay Date", sort: -1, calculate: e => e.date, },
  //   { key: "invoiceDate", title: "Invoice Date", calculate: e => e.invoiceLine.line.Date, },
  //   {
  //     key: "name", title: "Customer", queryColumn: true,
  //     calculate: e => e.paymentLine?.line.customerLedgerLine?.customer.billing?.Name,
  //     link: e => `/Customer/edit/${e.paymentLine?.line.customerLedgerLine?.customer.id}`
  //   },
  //   {
  //     key: "Amount", title: "Amount", filterType: "currency",
  //     calculate: e => e.paymentLine?.line.customerLedgerLine?.Amount,
  //   },
  //   // { 
  //   //   // this should probably go in an errors table
  //   //   key: "SameCustomer", title: "Same Customer", filterType: "boolean",
  //   //   calculate: e => e.invoiceLine.rental.customer?.id === e.paymentLine?.line.customerLedgerLine?.customer.id,
  //   // },
  //   {
  //     key: "PaymentStatus", title: "Payment Status",
  //     calculate: e => e.paymentLine?.PaymentStatus,
  //     schemaType: DataListColumn.getFieldType("AutopayAttempt", x => x.paymentLine.PaymentStatus.__),
  //   },
  // ], [], async () => {
  //   return await data.prisma.autopayAttempt.findMany({
  //     select: PrismaQuery.selectPathsProxy("AutopayAttempt", x => [
  //       x.id.__,
  //       x.date.__,
  //       x.invoiceLine.line.Date.__,
  //       x.paymentLine.id.__,
  //       x.paymentLine.PaymentStatus.__,
  //       x.paymentLine.line.customerLedgerLine.customer.id.__,
  //       x.paymentLine.line.customerLedgerLine.customer.billing.Name.__,
  //       // x.paymentLine.line.customerLedgerLine.customer.billing.Phone.__,
  //       x.paymentLine.line.customerLedgerLine.Amount.__,
  //     ] as const),
  //   });
  // }, [curTab, curBranch], "id") : useTableData(() => [
  //   { key: "Date", title: "Date", calculate: e => e.Date, sort: -1 },
  //   { key: "name", title: "Customer", queryColumn: true, calculate: e => e.Name, link: e => `/Customer/edit/${e.customerID}` },
  //   { key: "email", title: "Email", queryColumn: true, calculate: e => e.Email },
  //   { key: "phone", title: "Phone", calculate: e => e.billing?.Phone },
  //   { key: "Amount", title: "Amount", filterType: "currency", calculate: e => e.Amount },
  //   { key: "unit", title: "Unit", calculate: e => e.unit?.Name, link: e => e.unit?.id && `/Unit/edit/${e.unit.id}` },
  //   { key: "branch", title: "Branch", calculate: e => e.unit?.currentBranch.DisplayName },
  //   // { key: "customerAutopay", title: "Customer Autopay", filterType: "boolean", calculate: e => e.customer.Autopay },
  //   // { key: "customerReady", title: "Customer Ready", filterType: "boolean", calculate: e => e.customer.Ready },

  //   simplebool("ever ready", e => e.nv?.canLineEverBeEligible),
  //   simplebool("not tried", e => !e.nv?.details.autopayAttempted),
  //   simplebool("past due", e => e.nv?.arePreviousLinesPaid),
  //   simplebool("cust ready", e => e.customer?.customerChecks),
  //   simplebool("autopayNow", e => e.nv?.autopayNow),
  //   { key: "autopayAttempts", title: "Autopay Attempts", filterType: "numeric", calculate: e => e.nv?.autopayAttempts.length },
  //   { key: "customerBalance", title: "Customer Balance", filterType: "currency", calculate: e => e.customer?.Balance },
  //   { key: "oldattemptAutopay", title: "Old Attempt Autopay", filterType: "boolean", calculate: e => e.ov?.attemptAutopay },
  //   simplebool("!isThisFirst", e => e.ov && !e.ov.isThisFirst),
  //   simplebool("firstLinePaid", e => e.ov && e.ov.firstLinePaid),
  //   simplebool("!thisLinePaid", e => e.ov && !e.ov.thisLinePaid),
  //   simplebool("!autopayAttempted", e => e.ov && !e.ov.autopayAttempted),
  //   simplebool("oldLinesPaid", e => e.ov && e.ov.oldLinesPaid),
  //   simplebool("!isFuture", e => e.ov && !e.ov.isFuture),
  //   simplebool("!isOld", e => e.ov && !e.ov.isOld),
  //   simplebool("customerAutoPay", e => e.ov && e.ov.customerAutoPay),
  //   simplebool("customerReady", e => e.ov && e.ov.customerReady),
  // ], [], () => tableloader(data), [], "id")


  // useTableData(() => [
  //   { key: "Date", title: "Date", calculate: e => e.Date, sort: -1 },
  //   {
  //     key: "name", title: "Customer", queryColumn: true,
  //     calculate: e => e.customerBilling?.Name,
  //     link: e => `/Customer/edit/${e.customerID}`
  //   },
  //   { key: "email", title: "Email", queryColumn: true, calculate: e => e.customerEmail },
  //   { key: "Amount", title: "Amount", filterType: "currency", calculate: e => e.Amount },
  //   { key: "unit", title: "Unit", calculate: e => e.rental.unit.Name, link: e => e.rental.unit.id && `/Unit/edit/${e.rental.unit.id}` },
  //   { key: "branch", title: "Branch", calculate: e => e.rental.unit.currentBranch.DisplayName },
  //   { key: "customerReady", title: "Customer Ready", filterType: "boolean", calculate: e => e.customerReady },
  //   { key: "customerAutopay", title: "Customer Autopay", filterType: "boolean", calculate: e => e.customerAutoPay },
  //   { key: "shouldAutopay", title: "Should Autopay", filterType: "boolean", calculate: e => e.shouldAutopay },
  //   { key: "hasOldLines", title: "Old Lines Paid", filterType: "boolean", calculate: e => e.oldLinesPaid },
  //   { key: "autopayAttempts", title: "Autopay Attempts", filterType: "numeric", calculate: e => e.autopayAttempts },
  //   { key: "customerBalance", title: "Customer Balance", filterType: "currency", calculate: e => e.customerBalance },
  // ], [], async () => {

  //   let newValue = await data.server.queryGetAutopayForCustomerLedger({ AND: [] });

  //   switch (curTab) {
  //     case tabEnum.All:
  //       return newValue;
  //     case tabEnum.Failed:
  //       return newValue.filter(e => {
  //         // if this is in the future, this isn't failed
  //         if (e.isFuture) return false;
  //         // if we will attempt it, this isn't failed
  //         if (e.attemptAutopay) return false;
  //         // if autopay succeeded, this isn't failed
  //         if (e.autopayAttempted && e.autopayAttemptsStatus.some(e => e && e.inArray(["Approved", "Cleared"]))) return false;
  //         // autopay failed or won't be tried
  //         if (!e.shouldAutopay || e.autopayAttempted || e.isOld || !e.customerAutoPay || !e.customerReady || !e.oldLinesPaid) return true;
  //         debugger;
  //         return false;
  //       });
  //     case tabEnum.Ready:
  //       return newValue.filter(e => e.attemptAutopay);
  //     default:
  //       throw new Error("Invalid tab");
  //   }

  // }, [curTab, curBranch], "id");
  type Line = Awaited<SRResult<"queryCustomerAutopayStatus_new">>[0]["LedgerLines"][0]
    & { customer: Awaited<SRResult<"queryCustomerAutopayStatus_new">>[0] }

  function simplebool(key: string, calculate: (e: Line) => any) { return ({ key, title: key, filterType: "boolean" as const, calculate }); }
  function simplecurrency(key: string, calculate: (e: Line) => any) { return ({ key, title: key, filterType: "currency" as const, calculate }); }
  const tableData = useTableData(() => [
    { key: "Date", title: "Date", calculate: e => e.Date, sort: -1 },
    {
      key: "name", title: "Customer", queryColumn: true,
      calculate: e => e.customer.billing?.Name,
      link: e => `/Customer/edit/${e.customer.id}`
    },
    { key: "email", title: "Email", queryColumn: true, calculate: e => e.customer.Email },
    { key: "Amount", title: "Amount", filterType: "currency", calculate: e => e.Amount },
    simplecurrency("balance", e => e.customer.Balance),
    simplecurrency("pastdue", e => e.customer.BalancePastDue),
    simplebool("c ready", e => e.customer.customerReady),
    simplebool("c autopay", e => e.customer.AutoPay),
    simplebool("autopay now", e => e.customer.customerChecks && e.autopayNow),
    // isForActiveRental && !isLineAlreadyPaid && canLineEverBeEligible && arePreviousLinesPaid && !isFuture
    simplebool("c checks", e => e.customer.customerChecks),
    simplebool("active rental", e => e.details.isForActiveRental),
    simplebool("not paid", e => !e.isLineAlreadyPaid),
    simplebool("eligible", e => e.canLineEverBeEligible),
    simplebool("old paid", e => e.arePreviousLinesPaid),
    simplebool("not future", e => !e.details.isFuture),
  ], [], async () => {
    const res = await data.server.queryCustomerAutopayStatus_new({ where: {}, includeOld: false });
    return res.flatMap(customer => customer.LedgerLines.map(e => ({ ...e, customer: customer as typeof customer & {} })));
  }, [], "id");

  return <CustomTable
    tableData={tableData}
    // curTab={curTab}
    // setTab={setTab}
    // tabLabels={setTab ? tabLabels : undefined}
    showFilters={showFilters}
    emptyMarkup={emptyMarkup}
    loadingMarkup={loadingMarkup}
    resourceName={{ singular: "Customer Invoice Line", plural: "Customer Invoice Lines" }}
    pagination
  />;

}
AutopayTable.tabLabels = tabLabels;

AutopayTable.tabEnum = tabEnum;
