import { useObservable } from "react-utils";
import { Button, InlineStack, Text } from "@shopify/polaris";
import Dinero from "dinero.js";
import { PaymentLedgerKeys, ok } from "common";
import { DataService } from "data-service";
import { FormsQuestionService } from "../utils/forms-question.service";
import { GroupDialog, QuestionGroup, QuestionInputNumber, QuestionRender } from "../utils";
import { Observable, startWith } from "rxjs";
import { CustomColumnState } from "../tables/CustomColumnState";

export function formatDinero(item: Dinero.Dinero | number) {
  if (typeof item === "number") item = Dinero({ amount: item });
  return item.toFormat("0,0.00");
}


type PaymentDialogType = GroupDialog<"CustomerLedger", "CREATE", QuestionGroup<"CustomerLedger", {
  Balance: QuestionRender<number>;
  Amount: QuestionInputNumber;
  Total: QuestionRender;
  Success: QuestionRender;
}>>;

export function makePaymentDialog(
  fq: FormsQuestionService,
  data: DataService,
  PaymentLedger: PaymentLedgerKeys,
  hostID: string,
  serverRequest: (amount: Dinero.Dinero) => Promise<{ success: any; reason: any; }>,
) {
  const dialog: PaymentDialogType = fq.createBasicGroupDialog("CustomerLedger", "CREATE", "", () => {

    const Balance = new QuestionRender<number>({
      render() {
        const balance = this.form.value;
        return (
          <InlineStack align="space-between">
            <Text as="span" variant="headingMd">Current balance: ${formatDinero(balance)}</Text>
            <Button onClick={() => { group.controls.Amount.form.setValue(balance); }}>Use balance</Button>
          </InlineStack>
        );
      },
      onLoadHook: async (query) => {
        if (PaymentLedger === "SalesTax") return;
        const customs = new CustomColumnState(PaymentLedger, data, []);
        customs.onLoadHook(query, [{ id: hostID }]);
        await query.addHookBeforeResponse();
        Balance.form.setValue(customs.hostBalanceLookup.map?.get(hostID) ?? 0);
      }
    });
    // useForward(Balance.form.value, balanceChanges)
    const group = new QuestionGroup({
      __typename: "CustomerLedger",
      controls: {
        Balance,
        Amount: new QuestionInputNumber({
          required: true,
          inputMode: "currency",
          title: "Amount",
          helptext: "Input format changed. Pay attention when entering amount.",
          min: 1,
        }),
        Total: new QuestionRender({
          render: () => {
            useObservable(group.controls.Amount.form.valueChanges);
            return <Text as="p" variant="headingMd">Total: ${formatDinero(Dinero({ amount: group.controls.Amount.form.value ?? 0 }))}</Text>;
          }
        }),
        Success: new QuestionRender({
          render: () => <Text as="p" variant="bodyMd">Accepted. Thank you.</Text>,
          hidden: true,
        })
      }
    });
    group.controls.Amount.form.valueChanges.subscribe(value => {
      const amount = Dinero({ amount: value ?? 0 });
      const amountFormatted = amount?.toFormat();
      dialog.showOkCancel = true;
      dialog.okLabel = PaymentLedger === "Customer" ? `Charge ${amountFormatted}` : `Credit ${amountFormatted}`;
      dialog.onRefreshRender.emit({});
    });
    return group;
  }, async () => { }, true);
  dialog.onClickSave = async () => {
    ok(dialog.group);
    const value = dialog.group.controls.Amount.form.value ?? 0;

    if (!dialog.group.controls.Success.hidden) throw new Error("Already accepted")
    if (value < 0) return false;
    if (value === 0) return false;

    const amount = Dinero({ amount: value });
    const { success, reason } = await serverRequest(amount);

    if (success) {

      dialog.group.controls.Balance.hidden = true;
      dialog.group.controls.Amount.hidden = true;
      dialog.group.controls.Success.hidden = false;
      dialog.showOkCancel = false;
      dialog.cancelLabel = "Close";
    } else if (reason) {
      dialog.error = reason;
    } else {
      dialog.error = "Something went wrong. Please contact support.";
      // alert("something went wrong");
    }

    return success;
  };
  return dialog;
}
