import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { BlockStack, Box, Button, ButtonGroup, Card, MenuGroupDescriptor, Page, Text } from '@shopify/polaris';
import { SRData, StringPathProxy, findValueInObject } from "common";
import * as PrismaExtra from "prisma-client";
import { DataService } from "data-service";
import pluralize from 'pluralize';
import { useCallback, useDeferredValue, useMemo, useState } from 'react';
import { useAngular, useLoadingMarkup } from "react-utils";
import { startWith } from 'rxjs';
import { useTableListInner } from "../tables/TableListInner";
import { TableView, customerTableViews } from '../tables/table-views';
import { FormsQuestionService, LedgerTables, QuestionGroup, QuestionRender, QuestionSimple, Render, UIService, useBranchSelector } from '../utils';


export function CustomerTableList() {
  const table = "Customer";
  const { get } = useAngular();
  const fq = get(FormsQuestionService);
  const ui = get(UIService);
  const data = get(DataService);
  const router = get(Router);
  const zone = get(NgZone);
  const actionGroups: MenuGroupDescriptor[] = [];

  const { curBranch, branchSelectorActionGroup } = useBranchSelector();

  if (data.status.branchType === "CENTRAL" && !LedgerTables.contains(table) && branchSelectorActionGroup)
    actionGroups.push(branchSelectorActionGroup);

  if (data.status.isArlen) {
    actionGroups.push({
      title: "Dev Actions",
      onClick: (openActions) => { openActions(); },
      actions: [
        { content: "Merge Customers", onAction: async () => { await mergeCustomers(fq, ui); } },
        { content: "Delete Customer", onAction: async () => { await deleteCustomer(fq, ui); } },
      ]
    });
  }

  const title = pluralize(table);

  const primaryAction = LedgerTables.contains(table) ? null : {
    content: 'Add ' + table,
    disabled: false,
    onAction: () => {
      table === "Customer" ? onClickCreateCustomer({ ui, fq }) : fq.showAdd(table, {});
    }
  };




  const firstView = curBranch ? 0 : 2;

  const [showName, setShowName] = useState(true);
  const [showEmail, setShowEmail] = useState(true);
  const [showBilling, setShowBilling] = useState(true);
  const [showStatus, setShowStatus] = useState(true);
  const [showBalance, setShowBalance] = useState(true);

  const loadingMarkup = useLoadingMarkup(pluralize(table).toLocaleLowerCase());
  const views: TableView[] = useMemo(() => customerTableViews({
    curBranch, showAutopay: false,
  }) ?? [], [curBranch]);
  const state = useTableListInner({
    table,
    views,
    firstView,
    loadingMarkup,
    emptyMarkup: (curview: number) => curview === 0 && !curBranch ? <p>No branch selected</p> : undefined,
    onSelectRow: (id) => {
      console.log("onSelectRow", table, id);
      if (id) fq.onClickEvent({ action: "edit", table, id });
      else fq.onClickEvent({ action: "add", table, params: {} });
    },
    // pagination: true,
    // selectable: "single",

  });

  const showgroups = JSON.parse(useDeferredValue(JSON.stringify({ showName, showEmail, showBilling, showStatus, showBalance })));

  state.cols.forEach(e => {
    const { showName, showEmail, showBilling, showStatus, showBalance } = showgroups;
    if (e.displayGroup === "Name") e.hidden = !showName;
    if (e.displayGroup === "Email") e.hidden = !showEmail;
    if (e.displayGroup === "Billing") e.hidden = !showBilling;
    if (e.displayGroup === "Status") e.hidden = !showStatus;
    if (e.displayGroup === "Balance") e.hidden = !showBalance;
  });

  return <Page
    fullWidth
    title={title}
    primaryAction={primaryAction}
    actionGroups={actionGroups}
  >
    <Card padding="0">

      <Box padding="300">
        <ButtonGroup variant='segmented'>
          <Button variant="secondary" pressed={showName} onClick={() => setShowName(show => !show)}>Name</Button>
          <Button variant="secondary" pressed={showEmail} onClick={() => setShowEmail(show => !show)}>Email</Button>
          <Button variant="secondary" pressed={showBilling} onClick={() => setShowBilling(show => !show)}>Billing</Button>
          <Button variant="secondary" pressed={showStatus} onClick={() => setShowStatus(show => !show)}>Status</Button>
          <Button variant="secondary" pressed={showBalance} onClick={() => setShowBalance(show => !show)}>Balance</Button>
        </ButtonGroup>
      </Box>
      {state.useMarkup()}

    </Card>
  </Page>;
}




export async function onClickCreateCustomer(self: { ui: UIService; fq: FormsQuestionService; }) {

  const dialog = self.fq.createBasicGroupDialog("Customer", "CREATE", "", () => {
    const group = self.ui.schema.CustomerCREATE("CREATE");
    group.subs.add(group.form.valueChanges.pipe(startWith(group.form.value)).subscribe((e) => {
      group.controls.BillingDay.hidden = e.CustomerType !== PrismaExtra.CustomerType.Commercial;
    }));
    group.addControl("toptext", new QuestionRender({
      order: group.controls.Email.order - 0.5,
      render: () => {
        return <Box padding="400">
          <BlockStack gap="400">
            <Text as="p" variant="headingMd">The customer onboarding process has changed slightly.</Text>
            <p>Once the customer logs in, they will be guided through the setup process.</p>
            <p>There is now a link on the customer page which they click on to sign the storage
              agreement after verifying their email.</p>
            <p>If the storage agreement needs email verification again, the email will now come from us
              instead of Docusign and looks very similar to the original verification email.</p>
          </BlockStack>
        </Box>;
      }
    }))
    group.addControl("password", new QuestionSimple("InputText", {
      title: "Password",
      attr_validators: { minLength: 8, pattern: /([0-9].*[a-z])|([a-z].*[0-9])/ },
      helptext: "The password must be at least 8 long and have at least one number (0-9) and one lowercase letter (a-z)",
      order: group.controls.Email.order + 0.5,
    }));
    return group;
  }, async function (value) {
    const nulls = findValueInObject(value, e => e === PrismaExtra.Prisma.DbNull);
    const { password } = value as any;
    const value2 = { ...value, IS_TESTING: false };
    const { id } = await this.data.server.serverCreateCustomer({ value: value2, nulls, password });
    this.onClose();
    self.fq.onClickEvent({ action: "edit", table: "Customer", id });
  });

  await dialog.pageSetup(false);

  return dialog;

}

async function mergeCustomers(fq: FormsQuestionService, ui: UIService) {
  const customerLookupColumns = {
    arrayList: StringPathProxy<"Customer">()(x => [
      x.billing.Name.__,
      x.Email.__,
      x.IS_TESTING.__,
    ]),
    arraySort: StringPathProxy<"Customer">()(x => [
      x.IS_TESTING.__,
      x.billing.Name.__,
    ]),
  };
  const dialog = fq.createBasicGroupDialog("Customer", "UPDATE", "", () => {
    return new QuestionGroup({
      __typename: "Customer",
      controls: {
        sourceID: ui.lookupField("UPDATE", { targetTable: "Customer" }, {
          title: "Source Customer",
          required: true,
          ...customerLookupColumns,
        }),
        finalID: ui.lookupField("UPDATE", { targetTable: "Customer" }, {
          title: "Target Customer",
          required: true,
          ...customerLookupColumns,
        }),
        transferEmail: new QuestionSimple("CheckBox", { title: "Transfer Emails" }),
        transferStorageAgreement: new QuestionSimple("CheckBox", { title: "Transfer Storage Agreement" }),
        transferPaymentInfo: new QuestionSimple("CheckBox", { title: "Transfer Payment Info" }),
        mergeBillingInfo: new QuestionSimple("CheckBox", { title: "Merge Billing Info" }),
        transferOtherContacts: new QuestionSimple("CheckBox", { title: "Transfer Other Contacts" }),
        // transferRentalsAndLedger: new QuestionSimple("CheckBox", { title: "Transfer Rentals and Ledger" }),
        transferRentalsAndLedger: new QuestionSimple("Hidden", {}),
        renameSource: new QuestionSimple("CheckBox", { title: "Rename Source Customer" }),
      } satisfies Record<keyof SRData<"serverMergeCustomers">, any>
    });

  }, async function (value) {
    // const value = this.group?.form.value;
    // if (!value) return;
    if (typeof value.sourceID !== "string") return;
    if (typeof value.finalID !== "string") return;
    if (value.sourceID === value.finalID) return;
    await fq.messageConfirmation("Are you sure?", "Are you sure you want to merge these customers?", async () => {
      await this.data.server.serverMergeCustomers({
        finalID: value.finalID,
        sourceID: value.sourceID,
        transferEmail: value.transferEmail ?? false,
        transferStorageAgreement: value.transferStorageAgreement ?? false,
        transferPaymentInfo: value.transferPaymentInfo ?? false,
        mergeBillingInfo: value.mergeBillingInfo ?? false,
        transferOtherContacts: value.transferOtherContacts ?? false,
        // transferRentalsAndLedger: value.transferRentalsAndLedger ?? false,
        renameSource: value.renameSource ?? false,
      });
      this.onSaveSuccess.emit({});
      this.onClose();
    });
  }, true);


  await dialog.pageSetup(false);
}

async function deleteCustomer(fq: FormsQuestionService, ui: UIService) {
  const customerLookupColumns = {
    arrayList: StringPathProxy<"Customer">()(x => [
      x.billing.Name.__,
      x.Email.__,
      x.IS_TESTING.__,
    ]),
    arraySort: StringPathProxy<"Customer">()(x => [
      x.IS_TESTING.__,
      x.billing.Name.__,
    ]),
  };
  const dialog = fq.createBasicGroupDialog("Customer", "UPDATE", "", () => {
    return new QuestionGroup({
      __typename: "Customer",
      controls: {
        customerID: ui.lookupField("UPDATE", { targetTable: "Customer" }, {
          title: "Customer to Delete",
          required: true,
          ...customerLookupColumns,
        }),
      }
    });

  }, async function (value) {
    if (typeof value.customerID !== "string") return;
    await fq.messageConfirmation("Are you sure?", "Are you sure you want to delete this customer?", async () => {
      await this.data.server.serverDeleteCustomer({ customerID: value.customerID, });
      this.onSaveSuccess.emit({});
    });
  }, true);


  await dialog.pageSetup(false);
}

