import { BlockStack, Button, ButtonGroup, Card, ColumnContentType, DataTable, MenuGroupDescriptor, Page, PageProps, Text } from "@shopify/polaris";
import { DataQueryGraph, ok, proxy, SPPI, truthy, WHERE_balanceWhereLine } from "common";
import * as PrismaExtra from "prisma-client";
import { DataService } from "data-service";
import { format } from "date-fns";
import { Dispatch, PropsWithChildren, SetStateAction, useMemo, useRef, useState } from "react";
import { useAngular, useAsyncEffect, useObservable, useRefresh } from "react-utils";
import { QuestionTableComp } from "../components/QuestionTableComp";
import { TableViewColumnCustom, CustomTable, useTableData, useTableCols } from "../tables/useCustomTable";
import { BranchPage, DataListColumn, FormsQuestionService, Render, UIService, useBranchSelector } from "../utils";
import { capitalize } from "@shopify/polaris/utilities/capitalize";

async function PromiseAllKeysAwaited<T extends Record<string, Promise<any>>>(proms: T): Promise<{ [K in keyof T]: Awaited<T[K]> }> {
  const entries = [...Object.entries(proms)].map(async ([k, v]) => ({ [k]: await v }));
  return (await Promise.all(entries)).reduce((n, e) => Object.assign(n, e), {} as any);
}


export function PageSystemReport() {
  const data = useAngular().get(DataService);
  if (!data.status.isAdmin) return <Page title="System Report">You are not an admin</Page>;
  return <Page title="System Report">
    <BlockStack gap="400">
      <Card><SystemMonthlyRevenue /></Card>
    </BlockStack>
  </Page>
}

export function PageBranchRevenue() {

  return <BranchPage title="System Report">{(curBranch) => {
    const [period, setPeriod] = useState<"month" | "quarter">("month");

    const where = useMemo((): RevenueReportWhere => ({
      branchLedgerLine: { branch: { id: curBranch } }
    }), [curBranch]);

    const reportMarkup = useRevenueReport({ ledger: "branchLedgerLine", period, where, setPeriod });

    return <Card>{reportMarkup}</Card>
  }}</BranchPage>;

}

class LedgerQuery {
  constructor(public where: PrismaExtra.Prisma.TransactionWhereInput) { }
}

const groups1: Record<string, PrismaExtra.Prisma.TransactionWhereInput> = {
  "customer": { customerLedgerLine: { is: {} } },
  "branch": { branchLedgerLine: { is: {} } },
  "owner": { ownerLedgerLine: { is: {} } },
  "division": { divisionLedgerLine: { is: {} } },
  "central": { centralLedgerLine: { is: {} } },
  "salesTax": { salesTaxLedgerLine: { some: {} } },
};

const keys2 = [
  "customerLedgerLine",
  "branchLedgerLine",
  "ownerLedgerLine",
  "divisionLedgerLine",
  "centralLedgerLine",
  // "salesTaxLedgerLine",
] as const;

export function SystemMonthlyRevenue() {
  const [ledger, setLedger] = useState<typeof keys2[number]>("customerLedgerLine");
  const [period, setPeriod] = useState<"month" | "quarter">("month");
  const where = useMemo(() => ({ [ledger]: { is: {} } }), [ledger]);
  return useRevenueReport({ ledger, period, where, setLedger, setPeriod });
}


interface RevenueReportWhere {
  centralLedgerLine?: undefined;
  customerLedgerLine?: { customer: PrismaExtra.Prisma.CustomerWhereInput; }
  divisionLedgerLine?: { division: PrismaExtra.Prisma.DivisionWhereInput; }
  branchLedgerLine?: { branch: PrismaExtra.Prisma.BranchWhereInput; }
  ownerLedgerLine?: { owner: PrismaExtra.Prisma.OwnerWhereInput; }
}
type RevenueReportLedgers = "customerLedgerLine" | "centralLedgerLine" | "divisionLedgerLine" | "branchLedgerLine" | "ownerLedgerLine";
interface RevenueReportProps {
  ledger: RevenueReportLedgers,
  setLedger?: Dispatch<SetStateAction<RevenueReportProps["ledger"]>>,
  period: "month" | "quarter",
  setPeriod?: Dispatch<SetStateAction<RevenueReportProps["period"]>>,
  where: RevenueReportWhere,
}



export async function queryBranchBalance(data: DataService, branch: PrismaExtra.Prisma.BranchWhereInput) {
  // const date = cubesDateTime.formatValue(Date.now());
  const line = {
    VoidSince: null,
    IS_TESTING: false,
    OR: [
      { invoiceLine: { paidOn: { not: null } } },
      { paymentLine: { PaymentStatus: { in: ["Cleared", "Approved"] } } },
      { paymentLine: null, invoiceLine: null }
    ]
  } as PrismaExtra.Prisma.TransactionWhereInput;
  const [fees, branches1, taxes] = await Promise.all([
    data.prisma.invoiceLine.groupBy({
      by: ["branchID"],
      _sum: { BranchPaymentFee: true },
      where: { line, branch }
    }),
    data.prisma.branchLedger.groupBy({
      by: ["branchID"],
      _sum: { Amount: true },
      where: { line, branch }
    }),
    data.prisma.salesTaxLedger.aggregate({
      _sum: { Amount: true },
      where: { line, branch }
    })
  ]);


  const paymentFeeLookup = new Map<string, number>();
  fees.forEach(e => e.branchID && paymentFeeLookup.set(e.branchID, e._sum?.BranchPaymentFee ?? 0));
  const paymentFeeMap = new Map<string, number>(paymentFeeLookup);
  const branches = branches1.map(e => [e.branchID!, (e._sum?.Amount ?? 0) - (paymentFeeLookup.get(e.branchID!) ?? 0)] as const)
  const positiveBalance = branches.filter(([branchID, balance]) => balance > 0).reduce((n, [branchID, balance]) => n + balance, 0);
  const negativeBalance = branches.filter(([branchID, balance]) => balance < 0).reduce((n, [branchID, balance]) => n + balance, 0);
  const paymentFee = branches.map(([branchID, balance]) => paymentFeeMap.get(branchID) ?? 0).reduce((n, e) => n + e, 0);
  return { positiveBalance, negativeBalance, paymentFee, taxes: taxes._sum?.Amount ?? 0, branches };
}

const status = ['Validated', 'Approved', 'Cleared', 'Declined', 'Bounced', 'Voided'] as PrismaExtra.PaymentStatus[];


function useRevenueReport({ ledger, period, where, setLedger, setPeriod }: RevenueReportProps) {
  const data = useAngular().get(DataService);

  {
    const argCheck = useRef({ ledger, period, where });
    if (where !== argCheck.current.where && ledger === argCheck.current.ledger) {
      console.error("where !== argCheck.current.where && ledger === argCheck.current.ledger");
    }
    argCheck.current = { ledger, period, where };
  }

  const { result: { result, keys2set = new Set<string>(), unpaid_revenue } = {}, loading } = useAsyncEffect(async () => {

    const res = await data.prisma.transaction.findMany({
      select: {
        createdAt: true,
        Date: true,
        Description: true,
        customerLedgerLine: true,
        branchLedgerLine: true,
        ownerLedgerLine: true,
        divisionLedgerLine: true,
        centralLedgerLine: true,
        paymentLine: true,
        invoiceLine: true,
        // salesTaxLedgerLine: true,
      },
      where: {
        VoidSince: null,
        IS_TESTING: false,
        [ledger]: where[ledger] ?? { is: {} },
      }
    });
    const result = new Map<string, { invoices: { amount: number, fees: number }; payments: Map<string, number>; }>();
    const result_default = () => ({ invoices: { amount: 0, fees: 0 }, payments: new Map() });
    const unpaid_revenue = result_default();
    const keys2set = new Set<string>();
    res.forEach(e => {
      if (e.invoiceLine && !e.invoiceLine.paidOn) {
        inner(unpaid_revenue, e);
      } else {
        const month = e.paymentLine ? e.Date.slice(0, 7) : e.invoiceLine ? e.invoiceLine.paidOn!.slice(0, 7) : "";
        const quarter = month.slice(0, 4) + "-Q" + (Math.floor((parseInt(month.slice(5)) - 1) / 3) + 1);
        const key1 = period === "quarter" ? quarter : month;
        inner(result.getForced(key1, result_default()), e);
      }
    });
    function inner(group: { invoices: { amount: number; fees: number; }; payments: Map<string, number>; }, e: typeof res[0]) {
      const val = e[ledger]?.Amount ?? 0;
      // refunds have both, and paymentLine takes precedence there.
      if (e.paymentLine) {
        const haskeys2 = keys2.filter(k => !!e[k]);
        if (haskeys2.length > 1 && e.paymentLine.PaymentStatus !== "Cleared") return;
        const key2 = (haskeys2.length > 1)
          ? haskeys2.filter(e => e !== ledger).map(e => e.slice(0, -10)).join("-")
          : e.paymentLine.PaymentStatus;
        keys2set.add(key2);
        group.payments.set(key2, (group.payments.get(key2) ?? 0) + val);
      }
      else if (e.invoiceLine) {
        group.invoices.amount += val;
        if (ledger === "branchLedgerLine")
          group.invoices.fees += e.invoiceLine.BranchPaymentFee ?? 0;
        if (ledger === "ownerLedgerLine")
          group.invoices.fees += e.invoiceLine.OwnerPaymentFee ?? 0;
      }
      else if (!e.invoiceLine && !e.paymentLine) console.log(e);
    }
    return { result, keys2set, unpaid_revenue };
  }, undefined, undefined, [ledger, period, where, data]);



  const { result: branchBalanceLookup } = useAsyncEffect(async () => {
    if (ledger !== "branchLedgerLine") return;
    return await queryBranchBalance(data, { AND: [where.branchLedgerLine?.branch].filter(truthy) });
  }, undefined, undefined, [ledger, where]);

  console.log(branchBalanceLookup);

  const isCentral = ledger === "centralLedgerLine";
  const isCustomer = ledger === "customerLedgerLine";
  const isBranch = ledger === "branchLedgerLine";
  const isOwner = ledger === "ownerLedgerLine";
  const isDivision = ledger === "divisionLedgerLine";


  const keys2sort = [...keys2set].sort((a: any, b: any) => status.indexOf(a) - status.indexOf(b));

  const keys2watch = JSON.stringify(keys2sort);

  const revenueTable = useTableData(() => [
    {
      title: "Date",
      key: "Date",
      calculate: e => e.key
    },
    {
      title: "Revenue",
      key: "invoices.amount",
      filterType: "currency",
      alignment: "end",
      calculate: e => e.invoices.amount
    },
    {
      title: "Fees",
      key: "invoices.fees",
      filterType: "currency",
      alignment: "end",
      hidden: !isBranch && !isOwner,
      calculate: e => e.invoices.fees
    },
    ...JSON.parse(keys2watch).map((status): TableViewColumnCustom<any, any> => ({
      title: status,
      key: `payments.${status}`,
      alignment: "end",
      filterType: "currency",
      calculate: e => e.payments.get(status) ?? 0,
    })),
  ], [keys2watch], async () => {
    return [...result?.entries() ?? []]
      .map(([key, val]) => ({ key, ...val }))
      .sort((a, b) => -a.key.localeCompare(b.key));
  }, [result], "key");


  const holdingData = useSummaryTable(loading, keys2sort, unpaid_revenue, isBranch, isOwner, result, branchBalanceLookup)


  return <BlockStack gap="400">
    {setLedger && <ButtonGroup variant="segmented">
      {keys2.map((key) => <Button key={key} onClick={() => { setLedger(key); }} variant={ledger === key ? "primary" : undefined}>
        {capitalize(key.slice(0, -10))}
      </Button>
      )}
    </ButtonGroup>}
    {setPeriod && <ButtonGroup>
      <Button onClick={() => setPeriod("month")} variant={period === "month" ? "primary" : undefined}>Month</Button>
      <Button onClick={() => setPeriod("quarter")} variant={period === "quarter" ? "primary" : undefined}>Quarter</Button>
    </ButtonGroup>}
    {!isCustomer && <Card padding="0">
      <CustomTable tableData={holdingData} />
    </Card>}
    <Card padding="0">
      {/* <DataTable columnContentTypes={columnContentTypes} headings={headings} rows={rows} totals={totalRow} /> */}
      <CustomTable tableData={revenueTable} />
    </Card>
  </BlockStack>;
}


function useSummaryTable(
  loading: boolean,
  keys2sort: string[],
  unpaid_revenue: { invoices: { amount: number; fees: number; }; payments: Map<any, any>; } | undefined,
  isBranch: boolean,
  isOwner: boolean,
  result: Map<string, {
    invoices: {
      amount: number;
      fees: number;
    };
    payments: Map<string, number>;
  }> | undefined,
  branchBalanceLookup: {
    positiveBalance: number;
    negativeBalance: number;
    paymentFee: number;
    taxes: number;
    branches: (readonly [string, number])[];
  } | undefined
) {

  const totals = [...result?.values() ?? []].reduce((n, e) => {
    n.invoices.amount += e.invoices.amount;
    n.invoices.fees += e.invoices.fees;
    keys2sort.forEach(status => n.payments.set(status, (n.payments.get(status) ?? 0) + (e.payments.get(status) ?? 0)));
    return n;
  }, {
    invoices: { amount: 0, fees: 0 }, payments: new Map<string, number>()
  });
  
  const totalPaymentAmounts = [...totals.payments.entries()].reduce((n, [s, e]) => (s.inArray(status) && !s.inArray(["Cleared", "Approved"])) ? n : n + e, 0);

  return {
    ...useTableCols<{ Name: string; Amount: number; bold?: boolean; }>(() => [
      {
        key: "Name", title: "Name", calculate: e => e,
        markup: e => <Text as="span" fontWeight={e.bold ? "bold" : void 0}>{e.Name}</Text>
      },

      {
        key: "Amount", title: "Amount", filterType: "currency", alignment: "end", calculate: e => e,
        markup: e => <Text as="span" fontWeight={e.bold ? "bold" : void 0}>{DataListColumn.textCubesDinero(e.Amount)}</Text>
      },
    ], [], "Name"),
    loading,
    rows: [
      {
        Name: "Potential Revenue (unpaid invoices)",
        Amount: unpaid_revenue?.invoices.amount
      },
      {
        Name: "Total Invoices Paid",
        Amount: totals.invoices.amount
      },
      ...isBranch || isOwner ? [{
        Name: "Total Fees",
        Amount: totals.invoices.fees
      }] : [],
      {
        Name: "Total Payments",
        Amount: totalPaymentAmounts
      },
      ...isBranch || isOwner ? [{
        Name: (isBranch ? "Balance after Fees" : "Holding Balance") + " (amount - fees + payments)",
        Amount: totals.invoices.amount - totals.invoices.fees + totalPaymentAmounts,
        bold: !isBranch,
      }] : [{
        Name: "Holding Balance (amount + payments)",
        Amount: totals.invoices.amount + totalPaymentAmounts,
        bold: true,
      }],
      ...isBranch && branchBalanceLookup ? [
        { Name: "Holding Balance", Amount: branchBalanceLookup.positiveBalance, bold: true },
        { Name: "Owed by Branches", Amount: -branchBalanceLookup.negativeBalance, bold: true },
        {
          Name: "Branch Balance (holding - owed) (should match \"Balance after Fees\")",
          Amount: branchBalanceLookup.positiveBalance + branchBalanceLookup.negativeBalance,
        },
        { Name: "Holding Sales Tax", Amount: branchBalanceLookup.taxes, bold: true },
      ] : []
    ],
  };
}

export function SystemCurrentBalances() {
  const { get, injector } = useAngular();
  const ui = get(UIService);
  const data = get(DataService);

  const tableData = useTableData(() => [
    { key: "Name", title: "Name", calculate: e => e.Name },
    { key: "Amount", title: "Amount", filterType: "currency", calculate: e => e.Amount },
  ], [], async () => {

    const query = new DataQueryGraph("CentralHoldingLine", "", "web_admin");

    function payoutBalanceWhereLine() {
      return ({
        VoidSince: null,
        IS_TESTING: false,
        OR: [
          { invoiceLine: { paidOn: { not: null }, } },
          { paymentLine: { PaymentStatus: { in: ["Cleared", "Approved"] }, } },
          // { paymentLine: null, invoiceLine: null, }
        ]
      }) satisfies PrismaExtra.Prisma.TransactionWhereInput;
    }

    const proms = ({

      BranchLedger2: (async () => {
        const { branches, salesTax, paymentFeeLookup } = await data.server.queryBranchBalance({ AND: [] });
        const paymentFeeMap = new Map<string, number>(paymentFeeLookup);
        const positiveBalance = branches.filter(([branchID, balance]) => balance > 0).reduce((n, [branchID, balance]) => n + balance, 0);
        const negativeBalance = branches.filter(([branchID, balance]) => balance < 0).reduce((n, [branchID, balance]) => n + balance, 0);
        const paymentFee = branches.map(([branchID, balance]) => paymentFeeMap.get(branchID) ?? 0).reduce((n, e) => n + e, 0);
        return { positiveBalance, negativeBalance, paymentFee };
      })(),
      OwnerLedger: query.addPromise(data.proxy.ownerLedger.aggregate({
        _sum: { Amount: true },
        where: {
          line: payoutBalanceWhereLine(),
        },
      })),
      DivisionLedger: query.addPromise(data.proxy.divisionLedger.aggregate({
        _sum: { Amount: true },
        where: {
          line: payoutBalanceWhereLine(),
        },
      })),
      CentralLedger: query.addPromise(data.proxy.centralLedger.aggregate({
        _sum: { Amount: true },
        where: {
          line: payoutBalanceWhereLine(),
        },
      })),
      SalesTax: query.addPromise(data.proxy.salesTaxLedger.aggregate({
        _sum: { Amount: true },
        where: {
          line: payoutBalanceWhereLine(),
        }
      })),
      // Before february first, the payment fee was added on top of the amount the 
      // customer paid so it never entered the ledger and was handled by the customer.
      // After february first, the payment fee is deducted from the payout to branch and owner.
      // CustomerPaymentFee: query.addPromise(data.proxy.paymentLine.aggregate({
      //   _sum: { PaymentFee: true },
      //   where: {
      //     line: {
      //       ...payoutBalanceWhereLine(),
      //       customerLedgerLine: {},
      //       Date: { gte: "2024-02-01" },
      //     },
      //     PaymentStatus: { in: ["Cleared", "Approved"] },
      //   }
      // })),
      /** The owner and branch payment fee are set based on the transactions they apply to */
      OwnerPaymentFee: query.addPromise(data.proxy.invoiceLine.aggregate({
        _sum: { OwnerPaymentFee: true },
        where: {
          paidOn: { not: null },
          line: {
            VoidSince: null,
            IS_TESTING: false,
          }
        }
      })),
      /** The owner and branch payment fee are set based on the transactions they apply to */
      BranchPaymentFee: query.addPromise(data.proxy.invoiceLine.aggregate({
        _sum: { BranchPaymentFee: true },
        where: {
          paidOn: { not: null },
          line: {
            VoidSince: null,
            IS_TESTING: false,
          }
        }
      })),
    });

    await data.dataGraphQuery(query, "requests");

    const {
      BranchPaymentFee,
      SalesTax,
      BranchLedger2: { negativeBalance, positiveBalance },
      OwnerPaymentFee,
      OwnerLedger,
      DivisionLedger,
      CentralLedger,
    } = await PromiseAllKeysAwaited(proms);


    return [
      { Name: "Central Ledger", Amount: CentralLedger._sum.Amount },
      { Name: "Division Ledger", Amount: DivisionLedger._sum.Amount },
      { Name: "Sales Tax", Amount: SalesTax._sum.Amount },
      // { Name: "Branch Balance", Amount: (BranchLedger._sum.Amount ?? 0) - (BranchPaymentFee._sum.BranchPaymentFee ?? 0) },
      { Name: "Branch Balance Holding", Amount: (positiveBalance ?? 0) },
      { Name: "Branch Balance Owed by Branches", Amount: (negativeBalance ?? 0) },
      { Name: "Branch Payment Fee (subtracted)", Amount: (BranchPaymentFee._sum.BranchPaymentFee ?? 0) },
      // { Name: "Branch Payment Fee (not subtracted)", Amount: (BranchPaymentFee._sum.BranchPaymentFee ?? 0) },
      { Name: "Owner Balance", Amount: (OwnerLedger._sum.Amount ?? 0) - (OwnerPaymentFee._sum.OwnerPaymentFee ?? 0) },
      { Name: "Owner Payment Fee (subtracted)", Amount: (OwnerPaymentFee._sum.OwnerPaymentFee ?? 0) },
      {
        Name: "System Holding Balance",
        Amount: 0
          + positiveBalance
          + (SalesTax._sum.Amount ?? 0)
          + (OwnerLedger._sum.Amount ?? 0)
          + (DivisionLedger._sum.Amount ?? 0)
          + (CentralLedger._sum.Amount ?? 0)
          // the payment fee is deducted by the processor so it does not exist in the holding balance
          - (BranchPaymentFee._sum.BranchPaymentFee ?? 0)
          - (OwnerPaymentFee._sum.OwnerPaymentFee ?? 0)
      },
      {
        Name: "Payout Holding Balance",
        Amount: 0
          + positiveBalance
          + (SalesTax._sum.Amount ?? 0)
          + (OwnerLedger._sum.Amount ?? 0)
          + (DivisionLedger._sum.Amount ?? 0)
          // + (CentralLedger._sum.Amount ?? 0)
          // the payment fee is deducted by the processor so it does not exist in the holding balance
          - (BranchPaymentFee._sum.BranchPaymentFee ?? 0)
          - (OwnerPaymentFee._sum.OwnerPaymentFee ?? 0)
      }
    ]
  }, [], "Name");

  return (
    <BlockStack gap="300">
      <CustomTable
        tableData={tableData}
        curTab={0}
        setTab={undefined}
        showFilters={false}
        onSelectRow={async () => { }}
        tabLabels={[]}
      />
    </BlockStack>

  );

}


